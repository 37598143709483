import {
  getItemBySubCategory,
  getRestaurantCategoryList,
  getSubCategoryByCategory,
} from "../../api/menuDetails";
import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "../../App.css";
import { Card, Container, Dropdown, Nav, Navbar } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../images/SDR logo.png";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

const MenuItems = () => {
  const { id } = useParams();
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const [items, setItems] = useState();

  const selectedRestaurant = 64;
  const getRestaurantCategory = async () => {
    const response = await getRestaurantCategoryList(selectedRestaurant);
    setCategory(response?.data);
    // setActiveTab(response?.data[0]?.id);
    return response?.data;
  };

  useEffect(() => {
    // const data = category.find(el => parseInt(el.id, 10) === parseInt(id, 10));
    setActiveTab(id);
    if (category?.length === 0) getRestaurantsubCategory(id);
  }, [id, category]);

  const getRestaurantsubCategory = async (id) => {
    const response = await getSubCategoryByCategory(id);
    setSubCategory(response?.data);
    setActiveSubCategoryTab(response?.data[0]?.id);
    return response?.data;
  };

  const getItems = async (id) => {
    const response = await getItemBySubCategory(id);
    setItems(response?.data);
    return response?.data;
  };

  useEffect(() => {
    getRestaurantCategory();
  }, []);
  const [isMobile, setIsMobile] = useState(false);
  const [activeTab, setActiveTab] = useState(""); // Default active tab

  useEffect(() => {
    const handleResize = () => {
      // Check if the screen is mobile (width less than 768px)
      setIsMobile(window.innerWidth < 500);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Check screen size on initial load

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Handling tab change for both Tabs and Dropdown
  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
  };

  const [activeSubCategoryTab, setActiveSubCategoryTab] = useState("");

  const handleSubCategoryTabSelect = (tabKey) => {
    setActiveSubCategoryTab(tabKey);
  };

  useEffect(() => {
    getRestaurantsubCategory(activeTab);
  }, [activeTab]);

  useEffect(() => {
    setItems([]);
    getItems(activeSubCategoryTab);
  }, [activeSubCategoryTab]);

  const arabicTabContent = (id) => {
    //
    return (
      subCategory && (
        <Tabs
          defaultActiveKey={subCategory[0]?.id}
          onSelect={handleSubCategoryTabSelect}
          activeKey={activeSubCategoryTab}
          id="uncontrolled-tab-example"
          className="justify-content-center mb-3"
        >
          {subCategory?.map((el) => (
            <Tab
              eventKey={el.id}
              title={el.name}
              style={{ minHeight: "300px" }}
            >
              {/* Item name images */}
              <div className="row">
                {items?.map((data) => (
                  <div className="col-md-4 mb-4">
                    <div className="item-card">
                      <img
                        src={process.env.REACT_APP_baseUrl + data?.itemImgUrl}
                        alt=""
                      />
                      <div className="d-flex justify-content-between p-3">
                        <h5 className="title">{data?.name}</h5>
                        {/* <div className="dotted-div"></div> */}
                        <div className="price">{data?.price}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Tab>
          ))}
        </Tabs>
      )
    );
  };

  return (
    <>
      <header>
        <Navbar
          expand="lg"
          //   fixed={`top ${isShrunk ? "reduced-nav" : ""}`}
          className="header-top"
        >
          <Container className="menu-header-container">
            <div className="row">
              <ul className="navbar-nav-footer social-nav-footer social-nav-header mb-2 mb-lg-0">
                <li className="nav-item">
                  <FontAwesomeIcon
                    icon={faFacebook}
                    className="fontAddressIcon"
                  />
                </li>
                <li className="nav-item">
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className="fontAddressIcon"
                  />
                </li>
                <li className="nav-item">
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    className="fontAddressIcon"
                  />
                </li>
                <li className="nav-item">
                  <FontAwesomeIcon
                    icon={faTwitter}
                    className="fontAddressIcon"
                  />
                </li>
              </ul>
            </div>
            <Navbar.Brand href="/">
              <img src={logo} alt="" />
            </Navbar.Brand>

            <div>
              <Link
                href="tel:1234567890"
                className={`phone-no-item menu-header-phone`}
              >
                <FontAwesomeIcon icon={faPhone} /> 23289777
              </Link>
              <Link
                href="tel:1234567890"
                className={`phone-no-item menu-header-phone menu-header-whatsapp`}
              >
                <FontAwesomeIcon icon={faWhatsapp} /> 93543747
              </Link>
            </div>
          </Container>
        </Navbar>
      </header>
      <section
        id="menu"
        className="menu-page-content mb-5"
        style={{ background: "#fff" }}
      >
        <div className="container">
          <div className="row">
            <h2 className="text-center mb-5">Our Menu</h2>
            <div className="tab-container mobile-display">
              {isMobile
                ? category && (
                    <>
                      <Dropdown onSelect={handleTabSelect}>
                        <Dropdown.Toggle variant="secondary" id="menu-dropdown">
                          {
                            category?.find(
                              (el) =>
                                parseInt(el.id, 10) === parseInt(activeTab, 10)
                            )?.name
                          }
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="w-100">
                          {category?.map((item) => (
                            <Dropdown.Item eventKey={item.id}>
                              {item.name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                      <div>{arabicTabContent()}</div>
                    </>
                  )
                : category && (
                    <>
                      <Tabs
                        defaultActiveKey={category[0]?.id}
                        onSelect={handleTabSelect}
                        activeKey={activeTab}
                        id="menu-page-catogories"
                        className="mb-2 flex-column nav-category-menu"
                      >
                        <Tab eventKey={"head"} title={"Categories"} disabled>
                          Categories
                        </Tab>
                        {category?.map((el) => (
                          <Tab eventKey={el.id} title={el.name}>
                            {/* Item subcategory name */}
                            {arabicTabContent(el.id)}
                          </Tab>
                        ))}
                      </Tabs>
                    </>
                  )}
            </div>
          </div>
        </div>
      </section>
      <footer className="pt-0">
        <div className="text-center copyright_section">
          &copy; 2024. All rights reserved
        </div>
      </footer>
    </>
  );
};
export default MenuItems;
