import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Card,
  Carousel,
  Col,
  Container,
  Dropdown,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import about_img from "../../images/abt.jpg";
import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils } from "@fortawesome/free-solid-svg-icons/faUtensils";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { faBagShopping } from "@fortawesome/free-solid-svg-icons";
import { faCakeCandles } from "@fortawesome/free-solid-svg-icons";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  faLocationArrow,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faFacebook,
  faInstagram,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import logo from "../../images/SDR logo.png";
import logoSmall from "../../images/SDR-Logo-small.png";
import bannerImg from "../../images/banner.jpeg";
import bannerImg2 from "../../images/banner-2.jpg";
import bannerImg3 from "../../images/banner-3.jpeg";
import {
  getItemBySubCategory,
  getRestaurantCategoryList,
  getSubCategoryByCategory,
} from "../../api/menuDetails";

function App() {
  const [activeSection, setActiveSection] = useState("");

  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const [items, setItems] = useState();

  const selectedRestaurant = 64;
  const getRestaurantCategory = async () => {
    const response = await getRestaurantCategoryList(selectedRestaurant);
    setCategory(response?.data);
    setActiveTab(response?.data[0]?.id);
    return response?.data;
  };

  const getRestaurantsubCategory = async (id) => {
    const response = await getSubCategoryByCategory(id);
    setSubCategory(response?.data);
    setActiveSubCategoryTab(response?.data[0]?.id);
    return response?.data;
  };

  const getItems = async (id) => {
    const response = await getItemBySubCategory(id);
    setItems(response?.data);
    return response?.data;
  };

  useEffect(() => {
    getRestaurantCategory();
  }, []);

  const handleScroll = () => {
    const sections = document.querySelectorAll("section");
    const scrollPosition = window.pageYOffset;

    sections.forEach((section) => {
      const sectionTop = section.offsetTop - 80;
      const sectionHeight = section.clientHeight;
      if (
        scrollPosition >= sectionTop - 50 &&
        scrollPosition < sectionTop + sectionHeight
      ) {
        setActiveSection(section.getAttribute("id"));
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isShrunk, setShrunk] = useState(false);

  useEffect(() => {
    const handler = () => {
      setShrunk((isShrunk) => {
        if (
          !isShrunk &&
          (document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20)
        ) {
          return true;
        }

        if (
          isShrunk &&
          document.body.scrollTop < 4 &&
          document.documentElement.scrollTop < 4
        ) {
          return false;
        }

        return isShrunk;
      });
    };

    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  }, []);

  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const counterElement = document.getElementById("counter");
      const oTop =
        counterElement?.getBoundingClientRect().top - window.innerHeight;

      if (!hasScrolled && window.scrollY > oTop) {
        document.querySelectorAll(".counter-value").forEach((element) => {
          const countTo = parseInt(element.getAttribute("data-count"), 10);
          const startCount = parseInt(element.innerText, 10);

          let currentCount = startCount;

          const animateCount = () => {
            const increment = countTo / 100; // Adjust for the smoothness of the count
            if (currentCount < countTo) {
              currentCount += increment;
              element.innerText = Math.floor(currentCount);
              requestAnimationFrame(animateCount);
            } else {
              element.innerText = countTo;
            }
          };

          animateCount();
        });
        setHasScrolled(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolled]);

  const aboutRef = useRef(null);
  const menuRef = useRef(null);
  const servicesRef = useRef(null);
  const contactRef = useRef(null);

  const handleSmoothScroll = (e, sectionRef) => {
    e.preventDefault(); // Prevent the default anchor behavior
    if (sectionRef.current) {
      const targetPosition =
        sectionRef.current.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: targetPosition - 100, // Scroll 100px above the div
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  const [isMobile, setIsMobile] = useState(false);
  const [activeTab, setActiveTab] = useState("arabic"); // Default active tab

  useEffect(() => {
    const handleResize = () => {
      // Check if the screen is mobile (width less than 768px)
      setIsMobile(window.innerWidth < 500);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Check screen size on initial load

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // // Handling tab change for both Tabs and Dropdown
  // const handleTabSelect = (tabKey) => {
  //   setActiveTab(tabKey);
  // };

  // Handling tab change for both Tabs and Dropdown
  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
  };

  const [activeSubCategoryTab, setActiveSubCategoryTab] = useState("");

  const handleSubCategoryTabSelect = (tabKey) => {
    setActiveSubCategoryTab(tabKey);
  };

  useEffect(() => {
    getRestaurantsubCategory(activeTab);
  }, [activeTab]);

  useEffect(() => {
    setItems([]);
    getItems(activeSubCategoryTab);
  }, [activeSubCategoryTab]);

  const arabicTabContent = (id) => {
    //
    return (
      subCategory && (
        <Tabs
          defaultActiveKey={subCategory[0]?.id}
          onSelect={handleSubCategoryTabSelect}
          activeKey={activeSubCategoryTab}
          id="uncontrolled-tab-example"
          className="justify-content-center mb-3"
        >
          {subCategory?.map((el) => (
            <Tab
              eventKey={el.id}
              title={el.name}
              style={{ minHeight: "300px" }}
            >
              {/* Item name images */}
              <div className="row">
                {items?.map((data) => (
                  <div className="col-md-4 mb-4">
                    <Card>
                      <img
                        src={process.env.REACT_APP_baseUrl + data?.itemImgUrl}
                        alt=""
                      />
                      <div className="d-flex justify-content-between p-3">
                        <h5 className="title">{data?.name}</h5>
                        <div className="price">{data?.price}</div>
                      </div>
                    </Card>
                  </div>
                ))}
              </div>
            </Tab>
          ))}
        </Tabs>
      )
    );
  };

  return (
    <div className="App">
      <header>
        <Navbar expand="lg" fixed={`top ${isShrunk ? "reduced-nav" : ""}`}>
          <Container>
            <Navbar.Brand href="#home">
              {isShrunk ? (
                <img src={logoSmall} alt="" />
              ) : (
                <img src={logo} alt="" />
              )}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="main-menu">
                <Nav.Link
                  className={`${activeSection === "home" ? "active-menu" : ""}`}
                  href="#home"
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  href="#!"
                  className={`${
                    activeSection === "about" ? "active-menu" : ""
                  }`}
                  onClick={(e) => handleSmoothScroll(e, aboutRef)}
                >
                  About Us
                </Nav.Link>
                <Nav.Link
                  className={`${activeSection === "menu" ? "active-menu" : ""}`}
                  href="#!"
                  onClick={(e) => handleSmoothScroll(e, menuRef)}
                >
                  Menu
                </Nav.Link>
                <Nav.Link
                  href="#!"
                  className={`${
                    activeSection === "services" ? "active-menu" : ""
                  }`}
                  onClick={(e) => handleSmoothScroll(e, servicesRef)}
                >
                  Services
                </Nav.Link>
                <Nav.Link
                  className={`${
                    activeSection === "contact-us" ? "active-menu" : ""
                  }`}
                  href="#!"
                  onClick={(e) => handleSmoothScroll(e, contactRef)}
                >
                  Contact Us
                </Nav.Link>
                <Nav.Link href="tel:23289777" className={`phone-no-item`}>
                  <FontAwesomeIcon icon={faPhone} /> 23289777
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
      <section id="home" className="main-banner">
        <Carousel>
          <Carousel.Item>
            <img src={bannerImg} alt="" />
            <Carousel.Caption>
              <Container>
                <div className="banner-overlay">
                  <h3 className="bannerHead">
                    Good vibes, great food,
                    <br /> amazing memories
                  </h3>
                  <p className="bannerSubHead">
                    Where SOPHISTICATION meets flavor in perfect harmony.
                  </p>
                </div>
              </Container>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img src={bannerImg2} alt="" />
            <Carousel.Caption>
              <Container>
                <div className="banner-overlay">
                  <h3 className="bannerHead">
                    Food that tells a story <br /> with every course
                  </h3>
                  <p className="bannerSubHead">
                    Experience the ART of fine dining on your plate.
                  </p>
                </div>
              </Container>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img src={bannerImg3} alt="" />
            <Carousel.Caption>
              <Container>
                <div className="banner-overlay">
                  <h3 className="bannerHead">
                    Elevate your dining experience, <br /> one dish at a time{" "}
                  </h3>
                  <p className="bannerSubHead">
                    Taste the EXTRAORDINARY in everyday bites.
                  </p>
                </div>
              </Container>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>

      <section id="about" ref={aboutRef} className="py-100">
        <div className="container">
          <h2 className="text-center mb-5">Our Story</h2>
          <Row className="about-content">
            <Col lg={6}>
              <img src={about_img} alt="" />
            </Col>
            <Col lg={6}>
              <p>
                Silver Diamond Restaurant is a name synonymous with culinary
                excellence. Established in the year 2004, from humble
                beginnings, we have catapulted ourself to a multicuisine eatery
                over the past 20 years. Serving Indian, Arabic, Chinese and
                various other delicacies to the melting pot populace of Salalah,
                we have carved out a niche among the beaneries of this wonderful
                city.
              </p>
              <p>
                Spread over a sizable area, there is more than ample space to
                accommodate 100 people at a stretch. There are cubicles of
                various dimensions to suit families of different sizes and of
                course the spacious common dining hall and also a mini party
                area to complement the amenities.
              </p>
              <p>
                We are situated in the heart of Salalah in a very easily
                accessible location (near New Salalah Government Health Centre)
                with sufficient car parking. You are most welcome to pay us a
                visit and allow your taste buds to embark on a delightful
                gastronomic journey.
              </p>
            </Col>
          </Row>
        </div>
      </section>

      <section id="menu" ref={menuRef} className="py-100">
        <div className="container">
          <div className="row">
            <h2 className="text-center mb-5">Our Menu</h2>
            {/* <div className="col-md-3"> */}
            {/* <h3 className="text-center mb-5">Our Categories</h3> */}
            <div className="tab-container mobile-display">
              {isMobile
                ? category && (
                    <>
                      <Dropdown onSelect={handleTabSelect}>
                        <Dropdown.Toggle variant="secondary" id="menu-dropdown">
                          {
                            category?.find(
                              (el) =>
                                parseInt(el.id, 10) === parseInt(activeTab, 10)
                            )?.name
                          }
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="w-100">
                          {category?.map((item) => (
                            <Dropdown.Item eventKey={item.id}>
                              {item.name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                      <div>{arabicTabContent()}</div>
                    </>
                  )
                : category && (
                    <Tabs
                      defaultActiveKey={category[0]?.id}
                      onSelect={handleTabSelect}
                      activeKey={activeTab}
                      id="uncontrolled-tab-example"
                      className="mb-2 flex-column nav-category-menu"
                    >
                      {category?.map((el) => (
                        <Tab eventKey={el.id} title={el.name}>
                          {/* Item subcategory name */}
                          {arabicTabContent(el.id)}
                        </Tab>
                      ))}
                    </Tabs>
                  )}
            </div>
          </div>
        </div>
      </section>

      <section id="services" ref={servicesRef} className="text-center py-100">
        <div className="container">
          <h2 className="mb-5">Our Services</h2>
          <div className="row">
            <div className="col-md-6 col-lg-3 mb-4">
              <div className="card">
                <FontAwesomeIcon icon={faUtensils} className="fontIcon" />
                <h5 className="serviceHead">Dining</h5>
                <p>
                  Our dining service offers a refined experience with a diverse
                  menu designed to suit any occasion. Enjoy exceptional cuisine
                  and service in a welcoming atmosphere.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-4">
              <div className="card">
                <FontAwesomeIcon icon={faBagShopping} className="fontIcon" />
                <h5 className="serviceHead">Take Away</h5>
                <p>
                  Our take-away service allows you to enjoy our delicious foods
                  at home. Convenient and flavorful, it's perfect for any time
                  you want a restaurant-quality meal without the dining in
                  experience.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-4">
              <div className="card">
                <FontAwesomeIcon icon={faTruck} className="fontIcon" />
                <h5 className="serviceHead">Home Delivery</h5>
                <p>
                  Our home delivery service brings our delectable dishes
                  straight to your door. With fast and reliable delivery, you
                  can savor our diverse menu without stepping out.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-4">
              <div className="card">
                <FontAwesomeIcon icon={faCakeCandles} className="fontIcon" />
                <h5 className="serviceHead">Accommodate all events</h5>
                <p>
                  We accommodate all events, offering a versatile space and
                  personalized service to meet your needs. our team ensures
                  every detail is tailored to create a memorable experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="counter-section"
        className="text-center py-100 counter-content"
      >
        {/* <img src={counterimg} alt="" /> */}
        {/* <div className="container">
          <div className="counter-bg">
            <img src={counterimg} alt="" />
          </div>
        </div> */}
        <div className="overlay"></div>
        <div id="counter" className="container">
          <div className="row justify-content-around">
            <div className="col-sm-6 col-md-2 counter-div">
              <div className="counter-data">
                <span className="counter-value" data-count="50">
                  0
                </span>
                +
              </div>
              <h6>Categories</h6>
            </div>
            <div className="col-sm-6 col-md-2 counter-div">
              <div className="counter-data">
                <span className="counter-value" data-count="100">
                  0
                </span>
                +
              </div>
              <h6>Food Items</h6>
            </div>
            <div className="col-sm-6 col-md-2 counter-div">
              <div className="counter-data">
                <span className="counter-value" data-count="25">
                  0
                </span>
                k+
              </div>
              <h6>Happy Customers</h6>
            </div>
            <div className="col-sm-6 col-md-2 counter-div">
              <div className="counter-data">
                <span className="counter-value" data-count="15">
                  0
                </span>
                k+
              </div>
              <h6>Deliveries</h6>
            </div>
          </div>
        </div>
      </section>

      <section id="contact-us" ref={contactRef}>
        <div className="contact-data-container">
          <div className="container">
            <div className="contact-data">
              <h4>Contact Us</h4>
              <ul className="content-contact">
                <li>
                  <FontAwesomeIcon
                    icon={faLocationArrow}
                    className="fontAddressIcon"
                  />
                  <p>
                    Silver diamond restaurant Salalah Oman <br />
                    Near New Salalah Health Center
                  </p>
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="fontAddressIcon"
                  />
                  <p>business@silverdiamond.com</p>
                </li>
                <li>
                  <FontAwesomeIcon icon={faPhone} className="fontAddressIcon" />{" "}
                  23289777
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    className="fontAddressIcon footer-whatsapp"
                  />{" "}
                  93543747
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3815.131644816951!2d54.08864317515674!3d17.017211583808166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTfCsDAxJzAyLjAiTiA1NMKwMDUnMjguNCJF!5e0!3m2!1sen!2sin!4v1734102375598!5m2!1sen!2sin"
            width="100%"
            height="500"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>

      <footer>
        <div className="container foot-content">
          <div className="row row justify-content-between">
            <div className="col-md-3 footer-about-sec">
              <h4>About Us</h4>
              <p>
                Silver Diamond Restaurant is a name synonymous with culinary
                excellence. Established in the year 2004, from humble
                beginnings.
              </p>
            </div>

            <div className="col-md-3 subscribe-section">
              <h4>Services</h4>
              <div className="mt-4">
                <ul className="navbar-nav-footer mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="#">
                      Dining
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#about">
                      Take Away
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#services">
                      Home Delivery
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#menu">
                      Accommodate all events
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 subscribe-section">
              <h4>Quick Links</h4>
              <div className="mt-4">
                <ul className="navbar-nav-footer mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="#">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#about">
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#services">
                      Services
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#menu">
                      Menu
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#contact-us">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 insta-section">
              <h4>Stay Connected</h4>
              <div className="row">
                <ul className="navbar-nav-footer social-nav-footer mb-2 mb-lg-0">
                  <li className="nav-item">
                    <FontAwesomeIcon
                      icon={faFacebook}
                      className="fontAddressIcon"
                    />
                  </li>
                  <li className="nav-item">
                    <FontAwesomeIcon
                      icon={faInstagram}
                      className="fontAddressIcon"
                    />
                  </li>
                  <li className="nav-item">
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      className="fontAddressIcon"
                    />
                  </li>
                  <li className="nav-item">
                    <FontAwesomeIcon
                      icon={faTwitter}
                      className="fontAddressIcon"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center copyright_section">
          &copy; 2024. All rights reserved
        </div>
      </footer>
    </div>
  );
}

export default App;
